import request from '@/utils/request'

// 查询店铺-开店套餐列表
export function listStoreClass(query) {
  return request({
    url: '/platform/store-class/list',
    method: 'get',
    params: query
  })
}

// 查询店铺-开店套餐分页
export function pageStoreClass(query) {
  return request({
    url: '/platform/store-class/page',
    method: 'get',
    params: query
  })
}

// 查询店铺-开店套餐详细
export function getStoreClass(data) {
  return request({
    url: '/platform/store-class/detail',
    method: 'get',
    params: data
  })
}

// 新增店铺-开店套餐
export function addStoreClass(data) {
  return request({
    url: '/platform/store-class/add',
    method: 'post',
    data: data
  })
}

// 修改店铺-开店套餐
export function updateStoreClass(data) {
  return request({
    url: '/platform/store-class/edit',
    method: 'post',
    data: data
  })
}

// 删除店铺-开店套餐
export function delStoreClass(data) {
  return request({
    url: '/platform/store-class/delete',
    method: 'post',
    data: data
  })
}
